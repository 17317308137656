import React, { useState } from 'react'
import css from './Search.module.css'
import Backgraphics from '../../assets/backgraphics.png'
import d1 from '../../assets/d1.png'
import d2 from '../../assets/d2.png'
import d3 from '../../assets/d3.png'
import d4 from '../../assets/d4.png'
import SearchIcon from '../../assets/search.png'
import Path318 from '../../assets/Path 318.png'
import MusicPlayer from '../MusicPlayer/MusicPlayer'
import VisibilitySensor from "react-visibility-sensor";
import { motion } from 'framer-motion'

const Search = () => {
    const [elementIsVisible, setElementIsVisible] = useState(false);
  const bg = {
    true: {
      left: "-44rem",
    },
    false: {
      left: "-50rem",
    },
  };
  const redimg = {
    true: {
      left: "18rem",
    },
    false: {
      left: "16rem",
    },
  };
  const musicimg = {
    true: {
      left: "2rem",
    },
    false: {
      left: "6rem",
    },
  };
  return (
    <div className={css.search}>
        {/* left side */}
        <div className={css.left}>
            <motion.img 
             variants={{bg}}
             animate={`${elementIsVisible}`}
             transition={{
               duration: 1,
               type: "ease-out",
             }}
            src={Backgraphics} alt="" />
            <img src={d1} alt="" />
            <img src={d2} alt="" />
            <motion.img 
            variants={redimg}
            animate={`${elementIsVisible}`}
            transition={{
              duration: 1.2,
              type: "ease-out",
            }}
            src={d3} alt="" />
            <motion.img
             variants={musicimg}
             animate={`${elementIsVisible}`}
             transition={{
               duration: 1,
               type: "ease-out",
             }}
            src={d4} alt="" />
        </div>
        {/* right side */}
        <div className={css.right}>
            
                <div className={css.searchbar}>
                    <input type="text" 
                    placeholder='Enter the keyword or URL'/>
                    <div className={css.searchIcon}>
                        <img src={SearchIcon} alt="" />
                    </div>
                </div>

                {/* tild icon */}
                <div className={css.tild}>
                    <img src={Path318} alt="" />
                </div>

                {/* PARAGRAPH */}
                <div className={css.paragraph}>
                    <span>Search Music by</span>
                    <span><b>Name or Direct URL</b></span>
                    <span>Duis feugiat congue metus, ultrices vulputate <br/> nibh viverra
                    eget. Vestibulum ullamcorper <br/> volutpat varius.</span>
                </div>

                {/* Music Player */}
                <VisibilitySensor
                onChange={(isVisible) => setElementIsVisible(isVisible)}
                >
                <MusicPlayer/>
                </VisibilitySensor>
            </div>
        </div>
   
  )
}

export default Search