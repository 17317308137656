import React from 'react'
import css from './DownloadAds.module.css'
import AppStore from '../../assets/App Store.png'
import GooglePlay from '../../assets/Google Play.png'

const DownloadAds = () => {
  return (
    <div className={css.download}>
        <div className={css.images}>
            <img src={AppStore} alt="" />
        </div>
        <div className={css.images}>
            <img src={GooglePlay} alt="" />
        </div>
    </div>
  )
}

export default DownloadAds