import React from 'react'
import DownloadAds from '../DownloadAds/DownloadAds'
import css from './Download.module.css'
import Path from '../../assets/Path 318.png'

const Download = () => {
  return (
    <div className={css.download}>
        <div className={css.items}>
            <img src={Path} alt="" />
            <span>Download The Best Music</span>
            <span>App Now!</span>
            <span>Duis geugiat congue metus, ultrices vulputate nibh viverra eget. Vestibulum ullamcorper volutat<br/>
            varius.</span>
            <DownloadAds/>
        </div>
    </div>
  )
}

export default Download