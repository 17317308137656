import React from 'react'
import css from './Experience.module.css'
import Path from '../../assets/Path 318.png'
import Group2 from '../../assets/Group 2.png'
import MusicIcon from '../../assets/music icon.png'
import Group4 from '../../assets/Group 4.png'
import Feature from '../Feature/Feature'


const Experience = () => {
  return (
    <div className={css.experience}>
        <img src={Path} alt="" />

        {/* heading */}
        <div className={css.heading}>
            <span>An Amazing App Can Change Your Daily Life</span>
            <span>Music Experience</span>
        </div>

        <div className={css.feature}>
            <Feature icon={Group2} title='For Live Music'/>
            <Feature icon={MusicIcon} title='For Daily Music'/>
            <Feature icon={Group4} title='For Artists'/>
        </div>
    </div>
  )
}

export default Experience