import React from 'react'
import css from './Header.module.css'
import muzicLogo from '../../assets/MuzicLogo.png'
import CenterMenu from '../CenterMenu/CenterMenu'

const Header = () => {
  return (
    <div className={css.header}>
        <img src={muzicLogo} alt="" className={css.logo}/>
        <CenterMenu/>
        <div className={css.buttons}>
            <button className={css.btn1}>Sign up</button>
            <button className={css.btn2}>Sign in</button>
        </div>
    </div>
  )
}

export default Header