import React, { useState } from 'react'
import css from './Feature.module.css'
import { motion } from 'framer-motion'
import VisiblitySensor from 'react-visibility-sensor'


const Feature = ({icon, title}) => {

  const variant = {
    true: {
      transform: 'scale(1)'
    },
    false: {
      transform: 'scale(0.5)'
    }
  };

  const [elementIsVisible, setElementIsVisible] = useState(false)
  return (
    <VisiblitySensor
    onChange={(isVisible) => setElementIsVisible(isVisible)}
    >
    <div className={css.feature}>
        {/* icon */}
        <div className={css.bg}>
        <motion.div 
        variants={variant}
        transition={{
          duration: 1,
          type: 'ease-out'
        }}
        className={css.icon}>
        <img 
        
        src={icon} alt="" />
        </motion.div>
        </div>

        <span>{title}</span>
       
        <span className={css.text}>
          Nunc elementum, dolor vitae lacinia pulvinar, augue felis scelerisque
          libero, sit amet laoreet lorem.
        </span>

        <div className={css.more}>
        <span>Learn More</span>
        </div>
    </div>
    </VisiblitySensor>
  )
}

export default Feature