import React, { useState } from 'react'
import DownloadAds from '../DownloadAds/DownloadAds'
import css from './Hero.module.css'
import BackGraphics from '../../assets/backgraphics.png'
import img1 from '../../assets/p 1.png'
import img2 from '../../assets/p 2.png'
import img3 from '../../assets/p 3.png'
import img4 from '../../assets/p 4.png'
import VisiblitySensor from 'react-visibility-sensor'
import {motion} from 'framer-motion'

const Hero = () => {

  const [elementIsVisible, setElementIsVisible] = useState(false);
  const bg = {
    true: {
      left: '7rem'
    },
    false: {
      left: '19rem'
    }
  };
  const musicPlayer = {
    true: {
      left: "295px",
    },
    false: {
      left: "235px",
    },
  };
  const rect = {
    true: {
      left: "11rem",
    },
    false: {
      left: "13rem",
    },
  }
  const heart = {
    true: {
      left: "9rem",
    },
    false: {
      left: "12.5rem",
    },
  };
  return (
    <VisiblitySensor
    onChange = {(isVisible) => setElementIsVisible(isVisible)} minTopValue={300}>
    <div className={css.wrapper}>
        {/* Left Side*/}
        <div className={css.headings}>
            <span>Experience The</span>
            <span>Best Quality Music...</span>
            <span>Donec leoreet nec celit citae aliquam. ut quia trincidunt oprus.
                <br/>
                Suspendisse in leo non risus tincidunt lovoritis.
            </span>

            {/* download ads */}
            <span className={css.text1}>Download now on IOS & Android</span>
            <DownloadAds/>
        </div>
         {/* Right Side*/}

         <div className={css.images}>
            <motion.img 
            variants={bg}
            animate={`${elementIsVisible}`}
            transition={{duration: 1, type: 'ease-out'}}
            src={BackGraphics} alt="" />
            <img 
            
            src={img1} alt="" />
            <motion.img 
            variants={musicPlayer}
            animate={`${elementIsVisible}`}
            transition={{
              duration: 1,
              type: "ease-out",
            }}
            src={img2} alt="" />
            <motion.img 
            variants={rect}
            animate={`${elementIsVisible}`}
            transition={{
              type: "ease-out",
              duration: 1,
            }}
            src={img3} alt="" />
            <motion.img 
            variants={heart}
            animate={`${elementIsVisible}`}
            transition={{
              type: "ease-out",
              duration: 1,
            }}
            src={img4} alt="" />
         </div>
    </div>
    </VisiblitySensor>
  )
}

export default Hero