import React from 'react'
import css from './CenterMenu.module.css'

const CenterMenu = () => {
  return (
    <div className={css.menu}>
        <ul className={css.links}>
            <li>Home</li>
            <li>About</li>
            <li>Performer</li>
            <li>Event Schedule</li>
        </ul>
    </div>
  )
}

export default CenterMenu