import logo from './logo.svg';
import './App.css';
import Header from './components/Header/Header';
import Hero from './components/Hero/Hero';
import Experience from './components/Experience/Experience';
import Search from './components/Search/Search';
import Download from './components/Downloads/Download';
import Footer from './components/Footer/Footer';

function App() {
  return (
    <div className='App'>
      <div className="app">
      <Header/>
      <Hero/>
      <Experience/>
      <Search/>
      <Download/>
      <Footer/>
      </div>
    </div>
  );
}

export default App;
