import React from 'react'
import CenterMenu from '../CenterMenu/CenterMenu'
import css from './Footer.module.css'
import { faFacebook, faTwitter, faYoutube, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Footer = () => {
  return (
    <div className={css.footer}>
        <CenterMenu/>

        {/* icons */}
        <div className={css.icons}>
            <div className={css.facebook}>
                <FontAwesomeIcon icon={faFacebook}/>
            </div>
            <div className={css.twitter}>
            <FontAwesomeIcon icon={faTwitter}/>
            </div>
            <div className={css.youtube}>
            <FontAwesomeIcon icon={faYoutube}/>
            </div>
            <div className={css.linkedin}>
            <FontAwesomeIcon icon={faLinkedin}/>
            </div>
        </div>

        {/* detail */}
        <div className={css.details}>
            <span>Duis fangueit congue metus, ultrices vulputate nibh viverra eget.
                Vestibulum ullamcorper volutpat varius.
            </span>
        </div>
    </div>
  )
}

export default Footer